import React from "react";
import clsx from "clsx";
import { ImageComponent } from "@/components/elements/Image";
import SocialLink from "@/components/elements/SocialLink";
import NavButton from "@/components/elements/NavButton";
import MarkdownComponent from "@/components/elements/Markdown";
import * as styles from "./styles.module.css";

interface Props {
  teamMember: MemberType & {
    teamMemberPosition: string;
  };
}

function TeamMemberSection({ teamMember }: Props) {
  return (
    <section className="pt-8 px-4 lg:pt-16 pb-16 mx-auto max-w-x-big">
      <div className="flex items-start max-xl:flex-col max-xl:items-center">
        <NavButton title="Back to team page" href="/#team" className="flex-1" />
        <div className="max-xl:my-4 px-4 mb-16">
          <h1 className="font-bold text-center lg:text-9xl lg:leading-relaxed text-5xl md:text-4xl lg:max-w-3xl">
            {teamMember.name}
          </h1>
          <p className="text-center text-secondary font-semibold mt-2 mb-6">
            {teamMember.teamMemberPosition}
          </p>
          {teamMember.email && teamMember.email.length > 0 && (
            <a
              className="mx-auto  block bg-secondary text-white rounded-lg px-14 py-4 xl:px-12  relative xl:whitespace-pre border-secondary border-2 hover:text-secondary hover:bg-transparent transition-all max-xl:mb-0 max-w-max"
              href={`mailto:${teamMember.email}`}
            >
              Reach out
            </a>
          )}
        </div>
        <div className="flex-1 flex justify-center flex-col items-end gap-4">
          <ul className="flex justify-center max-xl:mt-0 max-xl:mb-8 flex-col gap-4 max-xl:flex-row max-xl:gap-6">
            {teamMember.socials.map((social) => (
              <li
                key={social._uid}
                className="w-10 h-10 p-2 bg-primary text-primary last:mr-0 rounded-full"
              >
                <SocialLink {...social} className="text-white" />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <ImageComponent
        className="max-w-3xl rounded-2xl overflow-hidden mb-7 m-auto"
        objectFit="fill"
        data={teamMember.photo}
        loading="lazy"
        fluid
      />
      <MarkdownComponent
        className={clsx("max-w-2xl m-auto text-lg py-16", styles.description)}
      >
        {teamMember.description}
      </MarkdownComponent>
    </section>
  );
}

export default TeamMemberSection;
